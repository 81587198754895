import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import DropDownList from "../../../../DropDownList/DropDownList";
import Input from "../../../../Input/Input";

const SubSectionSection = ({isLoading, parking, value, onSubSectionChange, onParkingChange}) => {
    const {t} = useTranslation();

    const onChange = useCallback((ev) => {
        if (onSubSectionChange) {
            onSubSectionChange(ev);
        }
    }, []);

    return (
        <>
        <div className='input-container syncfusion-control-input-container target-form-container'>
            <label className='label required'>
                {t("locationTarget.sub-section")}
            </label>
            <DropDownList url='/api/target/getSubSections'
                          popupHeight='250px'
                          change={onChange}
                          placeholder={t("locationTarget.undefined")}
                          value={value}
                          enabled={!isLoading}
                          showClearButton={true}/>
        </div>
    <div className='target-form-container'>

        <Input name={'subsectionParking'} type={"checkbox"} checked={parking} disabled={isLoading}  onChange={onParkingChange} />
        <label style={{marginLeft:'5px',verticalAlign: 'top'}} className='label'>
            {t("locationTarget.parking")}
        </label>

    </div>
        </>
    );
};

export default SubSectionSection;
