import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import cl from './TargetListItem.module.css';

const TargetListItem = ({label, id, onEditButtonClick, onRemoveButtonClick}) => {
    return (
        <div className={cl.locationTargetInputContainer}>
            <div>
                <input disabled={true}
                       className={cl.locationTargetInput}
                       value={label}
                       type='text'/>
                <span className={cl.editIconSpan}>
                    <FontAwesomeIcon icon={faPen} onClick={() => onEditButtonClick(id)}/>
                </span>
                <span className={cl.removeIconSpan}>
                    <FontAwesomeIcon icon={faTrash} onClick={() => onRemoveButtonClick(id)}/>
                </span>
            </div>
        </div>
    );
};

export default TargetListItem;