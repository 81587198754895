export const isTargetValid = (target) => {
    if (!target) {
        return false;
    }

    if (target.homeOwner !== null &&
        target.homeOwner.key !== null &&
        target.homeOwner.value !== null) {
        return true;
    }

    if (target.subSection !== null &&
        target.subSection.key !== null &&
        target.subSection.value !== null) {
        return true;
    }

    return target.section !== null &&
        target.section.key !== null &&
        target.section.value !== null;
}

export const isTabDisabled = (tab, targetModel) => {
    if (!targetModel)
        return false;

    switch (tab) {
        case "0":
            return (targetModel.homeOwner != null &&
                    targetModel.homeOwner.key != null &&
                    targetModel.homeOwner.value != null) ||
                targetModel.subSection != null &&
                targetModel.subSection.key != null &&
                targetModel.subSection.value != null;
        case "1":
            return (targetModel.section != null &&
                    targetModel.section.key != null &&
                    targetModel.section.value != null) ||
                targetModel.subSection != null &&
                targetModel.subSection.key != null &&
                targetModel.subSection.value != null;
        case "2":
            return (targetModel.section != null &&
                    targetModel.section.key != null &&
                    targetModel.section.value != null) ||
                targetModel.homeOwner != null &&
                targetModel.homeOwner.key != null &&
                targetModel.homeOwner.value != null;
    }

    return false;
}

export const checkIfSimilarTargetExists = (targets, target) => {
    if (!targets || targets.length <= 0) {
        return false;
    }

    return targets.find(item => item.buildingType === target.buildingType &&
        item.section.key === target.section.key && item.section.value === target.section.value &&
        item.homeOwner.key === target.homeOwner.key && item.homeOwner.value === target.homeOwner.value &&
        item.subSection.key === target.subSection.key && item.subSection.value === target.subSection.value &&
        item.street.key === target.street.key && item.street.value === target.street.value &&
        item.building.key === target.building.key && item.building.value === target.building.value &&
        item.id !== target.id);
}

export const getTargetLabel = (target) => {
    if (!target) {
        return '';
    }

    if (target.homeOwner !== null && target.homeOwner.key !== null && target.homeOwner.value !== null) {
        return target.homeOwner.value;
    }

    if (target.subSection !== null && target.subSection.key !== null && target.subSection.value !== null) {
        return target.subSection.value;
    }

    if (target.section !== null && target.section.key !== null && target.section.value !== null) {
        if (target.buildingType !== null) {
            return `${target.section.value} (${target.buildingType === 0 ? 'Коттеджи' : 'Многоэтажки'})`;
        }

        if (target.street != null && target.street.key !== null && target.street.value !== null) {
            if (target.building != null && target.building.key !== null && target.building.value !== null) {
                return `${target.section.value}, ул. ${target.street.value}, д. ${target.building.value}`;
            }

            return `${target.section.value}, ул. ${target.street.value}`;
        }

        return target.section.value;
    }
}